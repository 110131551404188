<template>
  <div ref="pdfvuer" class="pdf-main" v-if="loading">
    <div style="text-align: center; display: inline-block">
      <van-button @click="handleBack" v-if="showBack" class="btn"
        >Guest Information and Request</van-button
      >
    </div>
    <van-icon v-if="showClose"
      name="close"
      @click="handleBack"
      :class="{ closeIcon: showBack }"
    />
    <pdfvuer
      ref="pdf"
      :src="pdfdata"
      v-for="i in numPages"
      :key="i"
      :id="i"
      :page="i"
      :resize="true"
      :scale="scale"
      style="width: 100%; border: 2px solid #858585"
    >
      <!-- <template slot="loading"> loading </template> -->
    </pdfvuer>
  </div>
</template>
 
<script>
import pdfvuer from "pdfvuer";
import { downFile } from "@/api/manage";
import { Toast } from "vant";
import moment from "moment-timezone";
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import Vue from "vue";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content: "width=device-width,initial-scale=1.0",
      },
    ],
  },
  components: {
    pdfvuer,
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      scale: "page-width",
      loading: true,
      screenWidth: 0,
      showBack: false,
      showClose: true,
      url: {
        getPdfFile: "/api/common/hotel/data/getPdf",
      },
      pdfLoading: null,
    };
  },
  created() {
    this.pdfLoading = Toast.loading({
      forbidClick: false,
      duration: 0,
    });
    let now = new Date().getTime();
  },
  mounted() {
    // let start = "";
    // document.addEventListener(
    //   "touchstart",
    //   (e) => {
    //     start = e.touches[0].pageY;
    //   },
    //   false
    // );
    // document.addEventListener(
    //   "touchmove",
    //   (e) => {
    //     // alert(e.touches.length);
    //     // if (e.touches.length == 1) {
    //     if (e.touches[0].pageY - start > 0) {
    //       e.preventDefault();
    //     }
    //     // }
    //   },
    //   { passive: false }
    // );

    this.screenWidth = document.body.clientWidth; //监听页面缩放
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.pdfLoading = Toast.loading({
      forbidClick: false,
      duration: 0,
    });
    // this.getPdf();
  },
  watch: {
    show: function (s) {
      if (s) {
        this.getPdf();
      }
    },
    screenWidth() {
      // this.getPdf();
      // console.log(this.$refs.pdf.pdfViewer);
      this.loading = false;
      this.$nextTick(() => {
        this.loading = true;
        this.getPdf();
      });
    },
  },
  methods: {
    handleBack() {
      window.history.back();
    },
    getPdf() {
      this.pdfLoading = Toast.loading({
        forbidClick: false,
        duration: 0,
      });
      var self = this;
      let fileUrl = this.$route.query.inno;
      this.showBack = this.$route.query.isPopWin;
      //URL不包含showClose或者showClose为true时，仍然保持原有逻辑，显示关闭按钮
      if(this.$route.query.showClose && this.$route.query.showClose == "false") {
        this.showClose = false;
      }
      if (
        fileUrl.search("hotelvoicebot") != -1 ||
        fileUrl.search("hotelfile") != -1 ||
        fileUrl.search("hibaysoft") != -1
      ) {
        self.pdfdata = pdfvuer.createLoadingTask(fileUrl);
        self.pdfdata.then((pdf) => {
          self.numPages = 0;
          self.numPages = pdf.numPages;
          // this.scale = "page-width";
          this.pdfLoading.clear();
        });
      } else {
        let form = {
          pdfUrl: fileUrl,
        };
        downFile(this.url.getPdfFile, form)
          .then((res) => {
            let pdfUrl = this.getObjectURL(res);
            self.pdfdata = pdfvuer.createLoadingTask(pdfUrl);
            self.pdfdata.then((pdf) => {
              self.numPages = pdf.numPages;
              this.pdfLoading.clear();
            });
          })
          .catch((error) => {
            this.pdfLoading.clear();
            this.$message.error(error.message);
            console.log("getPdf error：" + error);
          });
      }
    },
    // 将返回的流数据转换为url
    getObjectURL(file) {
      let url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        try {
          url = window.webkitURL.createObjectURL(file);
        } catch (error) {
          console.log(error);
        }
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        try {
          url = window.URL.createObjectURL(file);
        } catch (error) {
          console.log(error);
        }
      }
      //   this.numPages = this.getPdfPagesNew(url);
      return url;
    },
  },
};
</script>
 
<style lang="scss" scoped>
.pdf-main {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  padding: 20px;
}
.btn {
  height: 35px;
  width: 290px;
  border-radius: 20px;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;

  span {
    font-size: 16px;
    line-height: 18px;
    font-family: $fontFamily;
    color: #ffffff;
  }
}
.van-icon {
  color: $primary-color;
  font-size: 30px;
  position: absolute;
  top: 5px;
  right: 15px;
}
.closeIcon {
  color: $primary-color;
  font-size: 30px;
  position: absolute;
  top: 60px;
  right: 15px;
}
</style>